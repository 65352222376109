import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import './App.css';
import Navigationbar from "./components/layout/NavigationBar/Navigationbar.jsx"
import Landing from "./components/pages/Landing/Landing.jsx";
import AboutMe from "./components/pages/AboutMe/AboutMe.jsx";
import Education from "./components/pages/Education/Education.jsx";
import WorkExperience from "./components/pages/WorkExperience/WorkExperience.jsx";
import Contact from "./components/pages/Contact/Contact.jsx";
import Footer from "./components/layout/Footer/Footer.jsx";
import Projects from "./components/pages/Projects/Projects.jsx";


function App() {
  return (
    <div className="container-fluid">
      <Router>
        <Navigationbar />
        <Routes>
          <Route exact path="/" element={<Landing/>} />
          <Route exact path="/about-me" element={<AboutMe/>} />
          <Route exact path="/work-experience" element={<WorkExperience/>} />
          <Route exact path = "/projects" element = {<Projects/>} />
          <Route exact path="/education" element={<Education/>} />
          <Route exact path="/contact" element={<Contact/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

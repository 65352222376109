import React from "react";
import { useState } from 'react';
import axios from "axios";

// import { Row, Col, Tab, Nav, Container } from "react-bootstrap";
import { Container } from "react-bootstrap";
import "./Contact.css";

const Contact = () => {
    // we set proxy in the package.json file to be "http://localhost:5000 so we can just do /email, it will 
    // automatically build the url as http://localhost:4000/email"
    const url = "https://shafirpl.com:443/api/email";
    //const url = "http://localhost/api/email";
    //const url = "/api/email";
    const positiveAlertMessage = "Message Sent";
    const negativeAlertMessage = "Sorry Something Went Wrong";
    const [successAlert, setSuccessAlert] = useState(false);
    const [failureAlert, setFailureAlert] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        message: ''
    })

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            company: '',
            message: ''
        })
    }
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        sendMessage();
        // console.log("Success");
        // console.log(formData);
    }

    const sendMessage = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const body = JSON.stringify({ name, email, company, message });

        try {
            const res = await axios.post(url, body, config);
            console.log(res);
            clearForm();
            showSuccessMessage();

        } catch (error) {
            console.log(error);
            showFailureMessage();
        }
    }

    const showFailureMessage = () => {
        setFailureAlert(true);
        setTimeout(() => {
            setFailureAlert(false)
        }, 3000);
    }

    const showSuccessMessage = () => {
        setSuccessAlert(true);
        setTimeout(() => {
            setSuccessAlert(false)
        }, 3000);
    }


    const { name, email, company, message } = formData;
    return (
        <div className="Contact">
            <Container>
                <p style={{ display: successAlert ? "block" : "none" }} className="alert">{positiveAlertMessage}</p>
                <p style={{ display: failureAlert ? "block" : "none" }} className="alert negative_alert">{negativeAlertMessage}</p>
                <h1 className="title">Contact Me</h1>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="form-group">
                        <label for="InputName">Name</label>
                        <input
                            name="name"
                            value={name}
                            onChange={e => handleOnChange(e)}
                            type="text"
                            className="form-control"
                            id="InputName"
                            aria-describedby="name"
                            placeholder="Your Name"
                            required />
                    </div>
                    <div className="form-group">
                        <label for="InputEmail">Email address</label>
                        <input
                            name="email"
                            value={email}
                            onChange={e => handleOnChange(e)}
                            type="email"
                            className="form-control"
                            id="InputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Your Email"
                            required />
                    </div>
                    <div className="form-group">
                        <label for="InputCompany">Company</label>
                        <input
                            name="company"
                            value={company}
                            onChange={e => handleOnChange(e)}
                            type="text"
                            className="form-control"
                            id="InputCompany"
                            aria-describedby="Company"
                            placeholder="Your Company"
                        />
                    </div>
                    <div className="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea
                            name="message"
                            value={message}
                            onChange={e => handleOnChange(e)}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="10"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Send</button>
                </form>
                <p style={{ display: failureAlert ? "block" : "none" }} className="alert negative_alert">{negativeAlertMessage}</p>
                <p style={{ display: successAlert ? "block" : "none" }} className="alert">{positiveAlertMessage}</p>
            </Container>
        </div>
    );
};

export default Contact;



import React from 'react';
import "./Footer.css";

const Footer = () => {
    return (
        <div className = "Footer">
            <p className ="footer-paragraph">Designed and made by me</p>
            <p className="footer-paragraph">Contact me for the repo as it is hosted on a private repo</p>
            
        </div>
    )
}

export default Footer

import React, {useState} from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
import {Navbar, Nav} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import "./Navigationbar.css"

/*
* https://stackoverflow.com/questions/54859515/react-bootstrap-navbar-collapse-not-working
* To make collapseOnSelect to work, which collapses the menu when we select an item,
* we need to add eventkey
*/

const Navigationbar = () => {
    // ml-auto allign items to the right
  const [expandedProp, setExpanded] = useState(false)
    return (
      <Navbar collapseOnSelect fixed="top" bg="dark" variant="dark" expand="md" expanded={expandedProp} onToggle={() => setExpanded(expandedProp ? false : true)}>
        <Navbar.Brand as={Link} to="/" eventKey="8" onClick={() => setExpanded(false)}>
          {" "}
          <FontAwesomeIcon icon={faLaptopCode} className="brand-icon" />
          Shafi
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto link-items">
            <Nav.Link as={Link} to="/about-me" activeClassName="active-nav" eventKey="1" onClick={() => setExpanded(false)}>About Me</Nav.Link>
            <Nav.Link as={Link} to="/work-experience" activeClassName="active-nav" onClick={() => setExpanded(false)}>Work Experience</Nav.Link>
            <Nav.Link as={Link} to ="/projects" activeClassName="active-nav" onClick={() => setExpanded(false)}>Projects</Nav.Link>
            <Nav.Link as={Link} to="/education" activeClassName="active-nav" eventKey="4" onClick={() => setExpanded(false)}>Education</Nav.Link>
            <Nav.Link eventKey="5" href="https://github.com/shafirpl/" target="_blank" onClick={() => setExpanded(false)}>
              GitHub
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto link-items">
            {/* <Nav.Link eventKey="6" activeClassName="active-nav" href="https://shafirpl.com/api/resume" target="_blank" rel="noopener noreferrer">Resume</Nav.Link> */}
            <Nav.Link eventKey="6" activeClassName="active-nav" href="https://shafirpl.com:443/api/resume">Resume</Nav.Link>
            <Nav.Link eventKey="7" activeClassName="active-nav" href="https://www.linkedin.com/in/shafi-hoque-a7238163/" target="_blank" rel="noopener noreferrer">LinkedIn</Nav.Link>
            <Nav.Link as={Link} to="/contact" activeClassName="active-nav" eventKey="8"  onClick={() => setExpanded(false)}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
}

export default Navigationbar